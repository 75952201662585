<pxc-tile 
  [size]="size"
  [vertical]="vertical"
>
  <pxc-avatar 
    leading 
    [size]="size"
    [name]="(user?.firstName || user?.lastName) ? user.firstName + ' ' + user.lastName : '@'"
  ></pxc-avatar>
  <ng-container title *ngIf="!user && mailAddress">{{ mailAddress }}</ng-container>
  <ng-container title *ngIf="user">
    <tui-line-clamp
      [content]="userIdentity"
      [linesLimit]="1"
      [lineHeight]="20"
      [ngClass]="{'max-w-24': vertical}"
    ></tui-line-clamp>
    <ng-template #userIdentity>
      <span>{{ user.firstName }} {{ user.lastName }}</span>
    </ng-template>
  </ng-container>
  <ng-container subtitle *ngIf="showSpecialty && user">
    <span>{{ user.medicalSpecialtyLabel }}</span>
    <span *tuiLet="(user.clientNames | filteredJoin:'Mon Espace Personnel') as clientNames">
      <span *ngIf="user.medicalSpecialtyLabel && clientNames">, </span>
      {{ clientNames }}
    </span>
    <span *ngIf="user && mailAddress" class="leading-tight">{{mailAddress}}</span>
  </ng-container>
</pxc-tile>