import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from './router.state';

export const ROUTER_FEATURE_KEY = 'Router';

const selectRouterState = createFeatureSelector<RouterReducerState<RouterState>>(ROUTER_FEATURE_KEY);

const getIdFromRouteParams = (key: string) =>
  createSelector(selectRouterState, ({ state }) => +(state?.params?.[key]) || null);

const getRouteParams = (key: string) =>
  createSelector(selectRouterState, ({ state }) => (state?.params?.[key]) || null);

export const selectClientCode = createSelector(selectRouterState, ({ state }) => state.queryParams?.cc?.toString());

export const selectDepartmentId = getIdFromRouteParams('departmentId');

export const selectPatientId = getIdFromRouteParams('patientId');

export const selectTelemonitoringId = getIdFromRouteParams('tmId');

export const selectChannelId = getRouteParams('channelId');

export const selectQueryParams = createSelector(selectRouterState, ({ state }) => state.queryParams);
