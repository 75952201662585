<button class="flex gap-6 p-6 ring-1 ring-slate-200 transition bg-white hover:shadow-slate-600/10 focus:shadow-slate-600/10 hover:shadow-lg hover:ring-0 focus:shadow-lg focus:ring-0 rounded-3xl text-left w-full h-full">
  <div>
    <tui-svg
      [src]="icon"
      data-testid="card-button-icon" class="mb-2"
    ></tui-svg>
    <h6>{{title}}</h6>
    <p class="text-slate-600 text-sm">{{description}}</p>
  </div>
  <tui-svg
    src="tuiIconChevronRightLarge"
    class="flex-shrink-0 text-slate-300 ml-auto self-center"
  ></tui-svg>
</button>
