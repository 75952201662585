/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { AvatarStyle } from '@pixacare/pxc-ts-core';

@Injectable({
  providedIn: 'root',
})
export class AvatarStyleService {

  getAvatarColors = (value: string): AvatarStyle => {
    if (value === '' || value === null || value === undefined) {
      return {
        backgroundColor: 'var(--tui-base-01)',
        borderColor: 'var(--tui-base-04)',
        textColor: 'var(--tui-text-01)',
      };
    }

    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
      // equivalent to : hash = value.charCodeAt(i) + ((hash * Math.pow(2, 5)) - hash);

      hash &= hash;
      // equivalent to : hash = Math.abs(hash);
    }

    const hue = hash % 360;
    const saturation = 55 + (hash % 5);
    const lightness = 90 + (hash % 5);

    return {
      backgroundColor: `hsl(${hue},${saturation}%,${lightness}%)`,
      borderColor: `hsl(${hue},${saturation}%,${lightness + 5}%)`,
      textColor: `hsl(${hue},${saturation}%,${lightness - 60}%)`,
    };
  };

  system(): AvatarStyle {
    return {
      backgroundColor: 'var(--tui-info-bg)',
      borderColor: 'var(--tui-info-bg)',
      textColor: 'var(--tui-primary)',
    };
  }

  alert(): AvatarStyle {
    return {
      backgroundColor: 'var(--tui-error-bg)',
      borderColor: 'var(--tui-error-fill)',
      textColor: 'var(--tui-error-fill)',
    };
  }

}
