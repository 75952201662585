import { Injectable } from '@angular/core';
import { Observable, combineLatest, iif, map, switchMap } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Store } from '@ngrx/store';
import { selectClientDepartment } from '../shared/store/departments/departments.selectors';
import { Department } from '../shared/models/base/department';
import {
  selectHasLicenseSupportAccess, selectHasLicenseWriteAccess, selectIsUserClientAdmin,
} from '../shared/store/licenses/licenses.selectors';
import { selectIsGamEnabled } from '../shared/store/clients/clients.selectors';
import { selectClientCode, selectDepartmentId } from '../shared/store/router/router.selectors';
import { Sequence } from '@pixacare/pxc-ts-core';
import { DepartmentAdminParams } from '../shared/models/department-admin-params';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationsService {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
  ) { }

  getUserId(): number {
    return this.authenticationService.currentUser.id;
  }

  isDepartmentOpen$(): Observable<boolean> {
    return this.getDepartment$().pipe(
      map((department) => department?.isOpen),
    );
  }

  isDepartmentAdmin$(): Observable<boolean> {
    return this.getDepartment$().pipe(
      map((department) => this.isDepartmentAdmin(department)),
    );
  }

  isDepartmentAdminParams$(clientCode: string, departmentId: number): Observable<boolean> {
    return this.store.select(selectClientDepartment(clientCode, departmentId)).pipe(
      map((department) => this.isDepartmentAdmin(department)),
    );
  }

  isDepartmentAdmin(department: Department): boolean {
    if (!department) {
      return false;
    }
    return department.memberships?.[this.getUserId()]?.isAdmin;
  }

  isClientAdmin$(): Observable<boolean> {
    return this.getParams$().pipe(
      switchMap(({ clientCode }) => this.isClientAdminParam$(clientCode)),
    );
  }

  isClientAdminParam$(clientCode: string): Observable<boolean> {
    return this.store.select(selectIsUserClientAdmin(
      this.getUserId(),
      clientCode,
    ));
  }

  isClientGamEnabled$(): Observable<boolean> {
    return this.getParams$().pipe(
      switchMap(({ clientCode }) => this.store.select(selectIsGamEnabled(clientCode))),
    );
  }

  hasLicenseWriteAccess$(): Observable<boolean> {
    return this.store.select(selectHasLicenseWriteAccess);
  }

  isClientOrDepartmentAdmin$(): Observable<boolean> {
    return combineLatest([
      this.isDepartmentAdmin$(),
      this.isClientAdmin$(),
    ]).pipe(
      map(([isDepartmentAdmin, isClientAdmin]) => isDepartmentAdmin || isClientAdmin),
    );
  }

  // department.isOpen OR user is admin in department or client
  isUserAuthorized$(): Observable<boolean> {
    return combineLatest([
      this.isDepartmentOpen$(),
      this.isClientOrDepartmentAdmin$(),
    ]).pipe(
      map(([isDepartmentOpen, isAdmin]) =>
        isDepartmentOpen || isAdmin),
    );
  }

  isUserAuthorizedForSequence(sequence: Sequence): Observable<boolean> {
    return iif(
      () => this.getUserId() === sequence.createdBy,
      this.hasLicenseWriteAccess$(),
      this.isUserAuthorized$(),
    );
  }

  hasSupportAccess(): Observable<boolean> {
    return this.store.select(selectHasLicenseSupportAccess);
  }

  private getDepartment$(): Observable<Department> {
    return this.getParams$().pipe(
      switchMap(({ clientCode, departmentId }) =>
        this.store.select(selectClientDepartment(clientCode, departmentId))),
    );
  }

  private getParams$(): Observable<DepartmentAdminParams> {
    return combineLatest({
      clientCode: this.store.select(selectClientCode),
      departmentId: this.store.select(selectDepartmentId),
    });
  }

}
